<template>
  <div class="app-container">
    <CrudTable
      v-if="currentActiveYN"
      ref="table"
      entity="AuditLog"
      :columns="columns"
      :allow-create="false"
      :filters="filters"
      :generate-summary="generateSummary"
      :allow-delete="false"
      :allow-actions="false"
      :disable-sorting="true"
      :on-row-dbl-click="() => {}"
      :use-pagination="false"
      is-embedded
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import crudIndexMixin from '@/mixins/crud-index';
import { isGiven } from '@/utils';

function fieldColumnFormatter(vue, field, entity) {
  const entityName = entity.charAt(0).toLowerCase() + entity.substring(1);
  const fieldName = field.charAt(0).toLowerCase() + field.substring(1);
  if (field.slice(-2) === 'ID') {
    const entityKey = `entity.${field.slice(0, -2)}`;
    const value = vue.$i18n.te(entityKey) ? vue.$i18n.t(entityKey) : entityKey;
    return value !== entityKey ? value : field;
  }
  const key = `${entityName}.${fieldName}`;
  const value = vue.$i18n.te(key) ? vue.$i18n.t(key) : key;
  return value !== key ? value : vue.$i18n.te(`common.${fieldName}`) ? vue.$i18n.t(`common.${fieldName}`) : field;
}

function changeTypeFilter(changeType) {
  const changeMap = {
    Update: 'warning',
    Create: 'success',
    Delete: 'danger',
    None: 'info'
  };
  return changeMap[changeType];
}

const changeTypeOptions = [
  { value: 'Update', label: 'Update' },
  { value: 'Create', label: 'Create' },
  { value: 'Delete', label: 'Delete' },
  { value: 'None', label: 'None' }
];

export default {
  name: 'AuditLogList',
  components: { CrudTable },
  mixins: [crudIndexMixin],
  props: {
    auditEntity: {
      type: String,
      required: true
    },
    auditEntityID: {
      type: Number,
      required: true
    },
    activeTab: String,
    historyTab: String,
    editor: Object,
    activeYN: Boolean
  },
  data() {
    return {
      currentActiveYN: this.activeYN,
      editorProps: {},
      columns: [
        {
          field: 'changeType',
          label: 'auditlog.changeType',
          width: 120,
          type: 'tag',
          tagFormatter: changeTypeFilter,
          filter: {
            type: 'select',
            options: changeTypeOptions,
            valueField: 'value',
            labelField: 'label'
          },
          multiple: true
        },
        {
          field: 'entity',
          label: 'auditlog.entity',
          width: 145,
          filterable: false,
          showOverflowTooltip: true
        },
        {
          field: 'fieldName',
          label: 'auditlog.fieldName',
          width: 235
        },
        {
          field: 'displayName',
          label: 'auditlog.displayName',
          formatter: auditlog => fieldColumnFormatter(this, auditlog.fieldName, this.auditEntity),
          width: 200,
          filterable: false,
          showOverflowTooltip: true
        },
        { field: 'oldValue', label: 'auditlog.oldValue', showOverflowTooltip: true },
        { field: 'newValue', label: 'auditlog.newValue', showOverflowTooltip: true },
        {
          field: 'mutatedAt',
          label: 'auditlog.mutatedAt',
          width: 170,
          type: 'dateTime',
          formatter: this.localTimeFormatter,
          format: 'DD-MM-YYYY HH:mm:ss',
          sortable: false
        },
        { field: 'mutatedBy', label: 'auditlog.mutatedBy', width: 135, showOverflowTooltip: true }
      ]
    };
  },
  computed: {
    displayName() {
      return this.fieldName;
    },
    editorActiveTab() {
      return this.editor && this.editor.activeTabModel;
    },
    editorHistoryTab() {
      return `${this.editor && this.editor.groups.length - 1}`;
    },
    filters() {
      return { entity: this.auditEntity, id: this.auditEntityID };
    }
  },
  watch: {
    activeYN(newVal) {
      this.currentActiveYN = this.currentActiveYN || newVal;
    },
    activeTab(newVal) {
      if (newVal === this.historyTab) {
        this.currentActiveYN = true;
      }
    },
    editorActiveTab(newValue) {
      if (isGiven(newValue) && newValue === this.editorHistoryTab) {
        this.currentActiveYN = true;
      }
    }
  },
  methods: {
    localTimeFormatter(row, column, value) {
      const utcTime = new Date(value);
      const dateString = utcTime.toString() + ' UTC';
      const newDate = new Date(dateString);
      const localDateTime = newDate.toString('DD-MM-YYYY HH:mm:ss');
      return localDateTime;
    },
    generateSummary() {
      const summary = `item`;
      return summary;
    }
  }
};
</script>

<style lang="scss">
.auditlog-container {
  .dse-text-area {
    .el-textarea.is-disabled {
      .el-textarea__inner {
        color: #080808;
        background: #fcfcfc;
        cursor: inherit;
      }
    }
  }
}
</style>
