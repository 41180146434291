<template>
  <div>
    <dossier-info-header-card class="no-float margin" :dossier-info="headerCardInfo"> </dossier-info-header-card>
    <crud-editor
      ref="editor"
      :is-edit="true"
      :definition="definition"
      :fetch-by-id="fetchById"
      :disabled="hasNoEditPermission"
      :save="saveData"
      :return-to="returnTo"
      :item-type="entity"
      :page-loading="loading"
      :get-dependencies="getDependencies"
    />
    <BaseButton
      :caption="$t('common.refresh')"
      :loading="loading"
      :icon-only="false"
      class="green-btn"
      @click="refreshScreen"
    />
    <div class="agreementform">
      <div class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="description"
          :agreement-by="0"
          :display-subject="true"
          :allow-save="true"
          :loading="loading"
        ></AgreementTable>
      </div>
      <div v-if="showManagerAgreement" class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="description"
          :agreement-by="1"
          :display-subject="false"
          :allow-save="false"
          :loading="loading"
        ></AgreementTable>
      </div>
      <div v-if="showBoardAgreement" class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="loanDescription"
          :agreement-by="2"
          :display-subject="false"
          :allow-save="false"
          :loading="loading"
        ></AgreementTable>
      </div>
    </div>
    <div class="app-container">
      <label title="Historie">Activiteiten logboek</label>
      <el-table ref="agreementlog" :data="agreements" stripe>
        <el-table-column property="agreementByText" :label="$t('common.role')" width="200" />
        <el-table-column property="userName" :label="$t('common.username')" width="200" />
        <el-table-column property="statusText" :label="$t('loan.agreementAction')" width="300" />
        <el-table-column property="date" :label="$t('common.date')" width="125" />
        <el-table-column property="time" :label="$t('common.time')" width="125" />
        <el-table-column property="remarks" :label="$t('common.remarks')" />
      </el-table>
    </div>
    <div>
      <AuditLog v-if="auditlog" :audit-entity="loanentity" :audit-entity-i-d="loanId" :active-y-n="true" />
    </div>
  </div>
</template>

<script>
import CrudEditor from '@/components/crud/Editor';
import formMixin from './form-mixin';
import { tryGetValue } from '@/utils';
import { hasEditPermissionForCode } from '@/utils/permission';
import DossierInfoHeaderCard from '@/pages/dossiers/DossierInfoHeaderCard';
import BaseButton from '@/components/crud/BaseButton';
import AgreementTable from '@/components/DSE/AgreementTable';
import AuditLog from '@/pages/dossiers/auditlogs/index';
export default {
  components: {
    CrudEditor,
    BaseButton,
    DossierInfoHeaderCard,
    AgreementTable,
    AuditLog
  },
  mixins: [formMixin],
  data() {
    return {
      auditlog: 0,
      headerCardInfo: {},
      loanInfo: [],
      loanAgreements: [],
      loanentity: 'Loan',
      agreementby: 0,
      description: '',
      loanId: 0,
      statusCode: {
        type: String
      },
      lastAgreementStatusCode: {
        type: String
      }
    };
  },
  computed: {
    hasNoEditPermission() {
      return !hasEditPermissionForCode(this.entity, this.$store.getters.permissions);
    },
    agreementType() {
      return this.agreementtype;
    },
    loanDescription() {
      return this.description;
    },
    loading() {
      return this.$store.getters[this.entityStore + '/isLoading'];
    },
    agreements() {
      return this.loanAgreements;
    },
    showManagerAgreement() {
      if (this.agreementby === 1 || this.lastAgreementStatusCode === 'AFWB' || this.lastAgreementStatusCode === 'ING') {
        return true;
      } else {
        return false;
      }
    },
    showBoardAgreement() {
      if (this.agreementby === 2 || this.lastAgreementStatusCode === 'ING') {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async fetchById(id) {
      const loan = await this.$store.dispatch(this.entityStore + '/getItem', {
        selector: { id }
      });
      const dossier = await this.$store.dispatch('dossier/getItem', {
        selector: { id: loan.dossierID }
      });
      const params = {};
      params.query = { loanid: id };
      this.$store.dispatch('loanagreement/getItems', params).then(data => {
        if (!data) {
          console.log('Geen loanagreements gevonden');
        } else {
          console.log('Loanagreements gevonden');
        }
        this.loanAgreements = tryGetValue(data, 'items') || [];
      });
      this.returntopage = this.returnTo;
      this.statusCode = loan.statusCode;
      this.lastAgreementStatusCode = loan.lastAgreementStatusCode;
      this.loanId = +id;
      this.headerCardInfo = dossier;
      this.loanInfo = loan;
      this.description = loan.displayName;
      if (loan.statusCode === 'TKB') {
        this.allowSave = false;
        this.agreementby = 3;
      } else {
        this.allowSave = true;
        if (loan.needsBoardAgreement) {
          this.agreementby = 2;
        } else {
          this.agreementby = 1;
        }
      }
      return this.createFormDataFromItem(this, loan);
    },
    saveData(form) {
      const item = this.createItemFromFormData(this, form);
      console.log(item);
      this.$forceUpdate();
      return this.$store.dispatch(this.entityStore + '/persistItem', item);
    },
    refreshScreen() {
      location.reload();
    }
  }
};
</script>

<style scoped>
.no-float {
  float: none;
}

.margin {
  margin: 10px;
}
</style>
<style>
.remark {
  margin: 5px;
  margin-left: 20px;
  height: 4em;
  width: 90%;
}

.green-btn {
  margin: 5px;
}

.red-btn {
  margin: 5px;
}

h4 {
  margin: 5px;
}
</style>
